import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import logo from 'assets/icons/logo.svg';
import { colors, mediaQueries, routes } from 'variables';

const Wrapper = styled.header`
  display: flex;
  padding: 20px 14px;
  position: sticky;
  top: 0;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.background};

  ${mediaQueries.lg} {
    background-color: ${colors.background};
    background-image: linear-gradient(rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const Logo = styled.img`
  width: 174px;
  height: 61px;
  cursor: pointer;
`;

export const Header: React.FC = () => {
  const history = useHistory();

  return (
    <Wrapper>
      <Logo src={logo} alt="Link to DEA main page" onClick={() => history.push(routes.home)} />
    </Wrapper>
  );
};
