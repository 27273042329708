import { Loader } from "components/common/Loader";
import { ProjectCard } from "components/project/ProjectCard";
import { apiClient } from "helpers/APIClient";
import { groupBy, isEmpty } from "lodash";
import React from "react";
import styled from "styled-components";
import { DEALabel, DEALabelObject, ProjectListEntry } from "types";
import {
  apiUrls,
  awardCategories,
  colors,
  mediaBase,
  mediaQueries,
} from "variables";
import Masonry from "react-masonry-css";

import StyledSelect, { Option } from "components/common/Select";

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 40px auto;
  padding: 16px;

  align-items: center;
  justify-content: center;
  text-align: center;

  .project-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: 0px;
    width: auto;
  }
  .project-grid-column {
    padding-left: 0px;
    background-clip: padding-box;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 0;
  line-height: 40px;
`;

const Year = styled.p<{ margin: number }>`
  font-size: 36px;
  margin-top: ${({ margin }) => `${margin}px`};
  margin-bottom: 80px;
`;

const AwardTab = styled.span<{ active: boolean }>`
  font-size: 20px;
  cursor: pointer;
  color: ${(props) => (props.active ? "white" : colors.secondary)};

  ${mediaQueries.md} {
    :not(:last-child) {
      margin-right: 32px;
    }
  }
`;

const HeaderSection = styled.div`
  width: 100%;
  margin: 72px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaQueries.md} {
    flex-direction: row;
    justify-content: center;
  }

  span {
    margin: 16px 0;
    ${mediaQueries.md} {
      margin: 0;
    }
  }
`;

const Dropdowns = styled.div`
  width: 700px;
  margin-bottom: 72px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQueries.md} {
    flex-direction: row;
  }
`;

const groupsLabels = ["GROUP_1", "GROUP_2", "GROUP_3"];

export const Homepage: React.FC = () => {
  const [projects, setProjects] = React.useState<ProjectListEntry[]>([]);
  const [isFetching, setIsFetching] = React.useState(false);
  const [activeCategory, setActiveCategory] = React.useState(
    awardCategories[0].key
  );

  React.useEffect(() => {
    setIsFetching(true);
    apiClient
      .get(apiUrls.projects)
      .then(({ data }) => {
        const filteredGroupsProjects: ProjectListEntry[] = data.map(
          (project: ProjectListEntry) => ({
            ...project,
            labels: project.labels.filter(
              (label) => !groupsLabels.includes(label.deaLabel)
            ),
          })
        );

        const projectsWithLabels = filteredGroupsProjects.filter(
          (project) => project.labels.length > 0
        );
        setProjects(projectsWithLabels);
      })
      .then(() => setIsFetching(false));
  }, []);

  const groupedProjects = React.useMemo(
    () =>
      Object.entries(groupBy(projects, "projectEdition"))
        .map(([edition, projects]) => ({
          edition,
          projects: projects
            .map((project) => ({
              ...project,
              labels: project.labels.filter(
                (label) => label.deaLabelCategory === activeCategory
              ),
            }))
            .filter((project) => !isEmpty(project.labels))
            .filter((project) =>
              project.awardCategory.includes(activeCategory)
            ),
        }))
        .reverse()
        .filter((group) => !isEmpty(group.projects)),
    [projects, activeCategory]
  );

  const [selectedYear, setSelectedYear] = React.useState<string | null>(null);
  const [selectedAward, setSelectedAward] = React.useState<string | null>(null);

  const yearSelectOptions: Option[] = [
    { value: null, label: "All years" },
    ...groupedProjects.map((group) => ({
      value: group.edition,
      label: group.edition,
    })),
  ];
  const awardSelectOptions = [
    { value: null, label: "All awards" },
    ...labelOptions,
  ];

  if (isFetching) return <Loader />;

  return (
    <Wrapper>
      <Title>Winners gallery</Title>
      <HeaderSection>
        {awardCategories.map((category) => (
          <AwardTab
            key={category.key}
            active={category.key === activeCategory}
            onClick={() => setActiveCategory(category.key)}
          >
            {category.label}
          </AwardTab>
        ))}
      </HeaderSection>
      <Dropdowns>
        <StyledSelect
          options={yearSelectOptions}
          value={
            yearSelectOptions.find((x) => x.value === selectedYear) ||
            yearSelectOptions[0]
          }
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={(x) => {
            setSelectedYear(x.value);
          }}
        />
        <StyledSelect
          options={awardSelectOptions}
          value={
            awardSelectOptions.find((x) => x.value === selectedAward) ||
            awardSelectOptions[0]
          }
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={(x) => {
            setSelectedAward(x.value);
          }}
        />
      </Dropdowns>

      {groupedProjects
        .filter((group) => !selectedYear || group.edition === selectedYear)
        .map((group, i) => (
          <React.Fragment key={group.edition}>
            {!selectedYear && (
              <Year margin={i === 0 ? 0 : 160}>{group.edition}</Year>
            )}

            <div style={{ width: "100%" }}>
              <Masonry
                breakpointCols={{
                  default: 4,
                  1250: 3,
                  900: 2,
                  650: 1,
                }}
                className="project-grid"
                columnClassName="project-grid-column"
              >
                {group.projects
                  .filter(
                    (project) =>
                      !selectedAward ||
                      project.labels.some(
                        (label) => label.deaLabel === selectedAward
                      )
                  )
                  .sort(
                    (a, b) =>
                      getHighestLabelWeight(b.labels) -
                      getHighestLabelWeight(a.labels)
                  )
                  .map((project, index) => (
                    <ProjectCard
                      key={project.id}
                      projectName={project.projectName}
                      projectId={project.id}
                      labels={project.labels}
                      projectImage={
                        project.projectEdition === "2024"
                          ? `${mediaBase}${project?.images[0]?.image}`
                          : `${mediaBase}compressed-x/${project?.images[0]?.image}`
                      }
                      description={project.description}
                      category={
                        awardCategories.find(
                          (cat) => cat.key === activeCategory
                        )!.label
                      }
                      index={index}
                      company={project.companyName}
                    />
                  ))}
              </Masonry>
            </div>
          </React.Fragment>
        ))}
    </Wrapper>
  );
};

function getHighestLabelWeight(labels: DEALabelObject[]) {
  if (labels.some((label) => label.deaLabel === DEALabel.WinnerOfTheYear))
    return 10;
  if (labels.some((label) => label.deaLabel === DEALabel.GoldPrize)) return 9;
  if (labels.some((label) => label.deaLabel === DEALabel.SilverPrize)) return 8;
  if (labels.some((label) => label.deaLabel === DEALabel.BronzePrize)) return 7;
  if (labels.some((label) => label.deaLabel === DEALabel.SpecialRecognition))
    return 6;
  if (labels.some((label) => label.deaLabel === DEALabel.HonorableMention))
    return 5;
  if (labels.some((label) => label.deaLabel === DEALabel.EmergingDesigner))
    return 4;
  if (labels.some((label) => label.deaLabel === DEALabel.SolaruxChoice))
    return 3;
  return 2;
}

const labelOptions = [
  {
    label: "Winner of the Year",
    value: DEALabel.WinnerOfTheYear,
  },
  {
    label: "Gold Prize",
    value: DEALabel.GoldPrize,
  },
  {
    label: "Silver Prize",
    value: DEALabel.SilverPrize,
  },
  {
    label: "Bronze Prize",
    value: DEALabel.BronzePrize,
  },
  {
    label: "Special Recognition",
    value: DEALabel.SpecialRecognition,
  },
  {
    label: "Honorable Mention",
    value: DEALabel.HonorableMention,
  },
  {
    label: "Emerging Designer",
    value: DEALabel.EmergingDesigner,
  },
  {
    label: "Solarlux Choice",
    value: DEALabel.SolaruxChoice,
  },
  { label: "Selected", value: DEALabel.Selection },
];
