import React from 'react';
import Cookies from 'js-cookie';
import { AwardCategory } from 'types';

interface Tokens {
  access: string;
  refresh: string;
}

export interface User {
  uid: string;
  fullName: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  bio: string;
  juryAssignedProjectCategories: AwardCategory[];
  slxMember: boolean;
}

export interface UserState {
  user: User;
  isAuth: boolean;
}

type Action =
  | { type: 'SET_USER_DATA'; payload: { user: User } }
  | { type: 'AUTH_USER' }
  | { type: 'LOGIN_USER'; payload: { tokens: Tokens } }
  | { type: 'LOGOUT_USER' };

interface ContextState {
  state: UserState;
  dispatch: (action: Action) => void;
}

const initialState: UserState = {
  user: {
    uid: '',
    fullName: '',
    street: '',
    zipCode: '',
    city: '',
    country: '',
    bio: '',
    juryAssignedProjectCategories: [],
    slxMember: false,
  },
  isAuth: !!Cookies.get('accessToken'),
};

const setAccessToken = (token: string): void => {
  Cookies.set('accessToken', token);
};

const setTokens = (accessToken: string, refreshToken: string): void => {
  setAccessToken(accessToken);
  Cookies.set('refreshToken', refreshToken);
};

const removeTokens = (): void => {
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
};

const setUserData = (user: User): Action => ({
  type: 'SET_USER_DATA',
  payload: { user },
});

const authUser = (): Action => ({
  type: 'AUTH_USER',
});

const loginUser = (tokens: Tokens): Action => ({
  type: 'LOGIN_USER',
  payload: { tokens },
});

const logout = (): Action => ({
  type: 'LOGOUT_USER',
});

function authReducer(state: UserState, action: Action): UserState {
  switch (action.type) {
    case 'SET_USER_DATA':
      return { ...state, user: action.payload.user };

    case 'AUTH_USER':
      return { ...state, isAuth: true };

    case 'LOGIN_USER':
      setTokens(action.payload.tokens.access, action.payload.tokens.refresh);
      return { ...state, isAuth: true };

    case 'LOGOUT_USER':
      removeTokens();
      return { ...initialState, isAuth: false };

    default:
      return state;
  }
}

const AuthContext = React.createContext<ContextState>({
  state: initialState,
  dispatch: () => undefined,
});

const AuthContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, initialState);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export {
  AuthContextProvider,
  AuthContext,
  setUserData,
  loginUser,
  authUser,
  logout,
  setTokens,
  removeTokens,
  setAccessToken,
};
