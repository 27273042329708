import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';

import { colors, mediaQueries } from 'variables';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;

    max-width: 95vw;
    max-height: 95vh;
    padding: 16px;
    padding-top: 8px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: ${colors.background};
    color: ${colors.secondary};
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4px;
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  margin-left: auto;
  background-color: transparent;
  color: ${colors.secondary};
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Image = styled.img`
  max-width: 99vh;
  max-height: 90vh;
  object-fit: contain;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  picture: string;
}

export const PhotoModal: React.FC<Props> = ({ isOpen, closeModal, picture }) => (
  <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
    <ModalStyles />
    <Header>
      <CloseButton onClick={closeModal}>X</CloseButton>
    </Header>

    <ImageWrapper>
      <Image src={picture} />
    </ImageWrapper>
  </Modal>
);
