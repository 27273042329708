import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { colors } from 'variables';

export interface Option {
  value: string | null;
  label: string;
}

interface Props {
  options: Option[];
  value: Option;
  onChange: any;
}

const customStyles: StylesConfig = {
  control: provided => ({
    ...provided,
    backgroundColor: 'transparent',
    color: 'white',
    border: '3px solid white',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    borderRadius: '0px',
    padding: '24px 36px',
    '&:hover': {
      border: `3px solid white`,
    },
    fontSize: '20px',
    width: '338px',
  }),
  valueContainer: provided => ({ ...provided, color: 'white' }),
  placeholder: provided => ({ ...provided, color: 'white' }),
  singleValue: provided => ({ ...provided, color: 'white' }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: '0 !important',
    '*': {
      padding: '0 !important',
    },
    svg: {
      color: 'white',
    },
  }),
  menu: provided => ({
    ...provided,
  }),
  menuList: provided => ({
    ...provided,
    backgroundColor: '#58585A',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    textDecoration: state.isSelected ? 'underline' : 'none',
    color: state.isSelected ? 'white' : colors.secondary,
    backgroundColor: 'transparent',
    fontSize: '16px',
    width: '100%',
    textAlign: 'left',

    ':hover': {
      backgroundColor: colors.background,
    },
  }),
};

const StyledSelect = ({ options, value, onChange }: Props): JSX.Element => (
  <Select
    styles={customStyles}
    options={options}
    defaultValue={options[0]}
    isSearchable={false}
    controlShouldRenderValue={false}
    placeholder={`${value.label}`}
    value={value}
    onChange={onChange}
  />
);

export default StyledSelect;
