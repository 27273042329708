import { AwardCategory, DEALabel } from 'types';

const mediaQ = (size: number) => `@media only screen and (min-width: ${size}px)`;

export const colors = {
  background: '#202020',
  primary: '#FFFFFF',
  secondary: '#8B8B8B',
  error: '#DB3832',
  success: '#44a12b',
};

export const mediaQueries = {
  xs: mediaQ(320),
  sm: mediaQ(576),
  md: mediaQ(850),
  lg: mediaQ(992),
  xl: mediaQ(1200),
  xxl: mediaQ(1440),
};

export const awardCategories = [
  { key: AwardCategory.ArchitecturalDesign, label: 'Architectural design' },
  { key: AwardCategory.ProductDesign, label: 'Product design' },
  { key: AwardCategory.ResponsiveDesign, label: 'Responsive design' },
  { key: AwardCategory.UniversalDesign, label: 'Universal design' },
];

export const projectRegex = /project\d+$/;

export const mediaBase = process.env.REACT_APP_MEDIA_URL;
export const apiBase = process.env.REACT_APP_API_URL;

export const apiUrls = {
  projects: `${apiBase}gallery/projects`,
  projectDetails: (projectId?: string): string => `${apiBase}gallery/projects/${projectId}/`,
};

export const routes = {
  login: '/login',
  home: '/',
  projects: '/projects',
  projectDetails: '/projects/:id',
  jury: '/jury',
};

export const getLabelColor = (label: DEALabel) => {
  switch (label) {
    case DEALabel.BronzePrize:
      return '#765015';
    case DEALabel.EmergingDesigner:
      return '#F29400';
    case DEALabel.Finalist:
      return '#009236';
    case DEALabel.GoldPrize:
      return '#FFED00';
    case DEALabel.HonorableMention:
      return '#694391';
    case DEALabel.SilverPrize:
      return '#58585A';
    case DEALabel.SolaruxChoice:
      return '#BE091D';
    case DEALabel.SpecialRecognition:
      return '#009EE0';
    case DEALabel.WinnerOfTheYear:
    case DEALabel.Selection:
      return '#FFFFFF';
  }
};

export const getLabelText = (label: DEALabel) => {
  switch (label) {
    case DEALabel.BronzePrize:
    case DEALabel.EmergingDesigner:
    case DEALabel.Finalist:
    case DEALabel.HonorableMention:
    case DEALabel.SilverPrize:
    case DEALabel.SolaruxChoice:
    case DEALabel.SpecialRecognition:
      return 'white';
    case DEALabel.GoldPrize:
    case DEALabel.WinnerOfTheYear:
    case DEALabel.Selection:
      return 'black';
  }
};
